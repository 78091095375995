document.addEventListener('click', function (e) {
	if (e.target && e.target.classList.contains('page-numbers') && e.target.nodeName === 'A') {
		e.preventDefault();

		const parts = e.target.href.split("?")[0].split('/');
		console.log(parts);
		let page = parts.pop() || parts.pop();  // handle potential trailing slash
		page = page;
		console.log(page);
		const pagination = e.target.closest(".pagination");
		const cat = pagination.dataset.cat;
		const postsPerPage = pagination.dataset.ppp;

		const url = new URL(`${pms.restUrl}/grid`);
		url.searchParams.append("page", page);
		url.searchParams.append("cat", cat);
		url.searchParams.append("ppp", postsPerPage);

		fetch(url.href)
			.then(res => {
				if (!res.ok) {
					return res.json().then(json => {
						throw json;
					});
				}
				return res.json().then(json => {
					const news = e.target.closest(".news");
					const scrollDiv = news.offsetTop;
					news.parentNode.innerHTML = json.html;
					window.scrollTo({ top: scrollDiv - 150, behavior: 'smooth'});

				});
			})
			.catch(err => {
				// err is not a promise
			});
	}
});
