/**
 * Carousel Flickity
 * ======================================
 * - https://flickity.metafizzy.co/
 */

import Flickity from "flickity";

const SELECTOR = ".js-carousel";
const SETTINGS = {
	cellAlign: "left",
	adaptiveHeight: true,
	prevNextButtons: false,
	autoPlay: true
};

class Carousel {
	constructor() {
		this.elements = document.querySelectorAll(SELECTOR);

		if (!this.elements) {
			return false;
		}

		this.elements.forEach((el) => {
			this.init(el);
		});
	}

	init = (el) => {
		new Flickity(el, SETTINGS);
	};
}

new Carousel();
