/**
 * Toggle
 * ======================================
 * - toggle class
 * - toggle ELEMENT_LABEL text with [data-toggle-label] attribute
 */

 const ELEMENTS = ".js-toggle";
 const ELEMENT_BUTTON = ".js-toggle-button";
 const ELEMENT_LABEL = ".js-toggle-label";
 const TOGGLE_CLASS = "is-open";
 const CLOSING_CLASS = "is-closing";
 const CLOSING_CLASS_DELAY = 250;

 class Toggle {
   constructor() {
     this.elements = document.querySelectorAll(ELEMENTS);

     if (!this.elements) {
       return false;
     }

     this.elements.forEach((el) => {
       el.querySelectorAll(ELEMENT_BUTTON).forEach((button) => {
        button.addEventListener("click", this.toggle);
       });
     });
   }

   toggle = (e) => {
     const target = e.target.closest(ELEMENTS);
     const label = target.querySelector(ELEMENT_LABEL);

     if (!target) {
       return false;
     }

     if (label) {
      this.switchLabel(label)
     }

     this.closeSiblings(target)

     if(target.classList.contains(TOGGLE_CLASS)){
      this.close(target)
    } else {
      target.classList.add(TOGGLE_CLASS);
    }

     e.preventDefault();
   };

   close = (target) => {
    target.classList.remove(TOGGLE_CLASS);
    target.classList.add(CLOSING_CLASS)

    setTimeout(() => {
      target.classList.remove(CLOSING_CLASS)
    }, CLOSING_CLASS_DELAY);
   }

   switchLabel = (label) => {
    const currentLabel = label.innerHTML;
    const newLabel = label.getAttribute("data-toggle-label");

    if(newLabel){
     label.innerHTML = newLabel;
     label.setAttribute("data-toggle-label", currentLabel);
    }
   }

   closeSiblings = (el) => {
    const siblings = el.parentNode.querySelectorAll(ELEMENTS+'.'+TOGGLE_CLASS)

    siblings.forEach((sibling) => {
      if(sibling != el) {
        const label = sibling.querySelector(ELEMENT_LABEL);
        this.close(sibling)
        this.switchLabel(label)
      }
    })
   }
 }

 new Toggle();
