require("./scripts/Animate.js");
require("./scripts/Toggle.js");
require("./scripts/Tabs.js");
require("./scripts/Carousel.js");
require("./scripts/ToggleNav.js");
require("./scripts/Pagination");
require("./scripts/Lightbox");
require("./scripts/Downloads");
//require("./scripts/Map");
require("./scripts/Search");
