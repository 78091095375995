// https://github.com/biati-digital/glightbox

import GLightbox from 'glightbox'

GLightbox({
	loop: false
})

const openButton = document.querySelectorAll('.glightbox-open')

openButton.forEach((item) => {
	item.addEventListener('click', (e) => {
		const target = item.getAttribute('data-trigger')
		const targetEl = document.querySelector(target)

		targetEl.click()
	})
})
const gallery = document.querySelectorAll('.blocks-gallery-item a');
if (gallery) {
	gallery.forEach(item => {
		item.classList.add('glightbox');
		item.setAttribute('data-gallery', 'gallery');
	});
	GLightbox({
		touchNavigation: true,
		loop: true,
		autoplayVideos: true
	});
}

