/**
 * Toggle Nav
 * ======================================
 * - toggle class on body
 */

const ELEMENTS = '.togglenav__button'
const SUBNAV_ELEMENTS = '.header__item--submenu > a'
const TOGGLE_CLASS = 'nav-is-open'
const IS_DESKTOP = window.innerWidth >= 1070

class ToggleNav {
	constructor() {
		this.elements = document.querySelectorAll(ELEMENTS)
		this.subnav_elements = document.querySelectorAll(SUBNAV_ELEMENTS)

		if (!this.elements) {
			return false
		}

		this.elements.forEach((el) => {
			el.addEventListener('click', this.toggleNav, false)
		})

		if (!IS_DESKTOP) {
			this.subnav()
		}
	}

	subnav = () => {
		this.subnav_elements.forEach((el) => {
			el.addEventListener('click', this.toggleSubNav, false)
		})

		// $('.menu-ul-level-1 > li > a').click(function() {
		//   const $this = $(this)
		//   const $parent = $this.closest('li')
		//   const hasSubnav = $parent.find('.menu-ul-level-2')
		//   const parentActive = $parent.hasClass('is-open')

		//   if(hasSubnav.length){
		//     $('.menu-ul-level-1 li.is-open').removeClass('is-open')

		//     if(parentActive){
		//       $parent.removeClass('is-open')
		//     } else {
		//       $parent.addClass('is-open')
		//     }

		//     return false;
		//   }
		// })
	}

	toggleSubNav = (e) => {
		const el = e.currentTarget
		const parent = el.parentElement
		console.log(parent.classList.contains('is-open'));
		if (!parent.classList.contains('is-open')) {
			e.preventDefault();
		}
		parent.classList.toggle('is-open')
	}

	toggleNav(e) {
		document.body.classList.toggle(TOGGLE_CLASS)
		document.body.classList.toggle('lock')

		e.preventDefault()
	}
}

new ToggleNav()
