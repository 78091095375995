document.querySelectorAll('.contacts-search form').forEach(item =>  {
	item.addEventListener('submit', (e) => {
		e.preventDefault();

		const val = item.getElementsByTagName('input')[0].value;

		fetch(`${pms.restUrl}/contacts/search?s=${val}`)
			.then(res => {
				if (!res.ok) {
					return res.json().then(json => { throw json; });
				}
				return res.json().then(json => {
					const results = item.closest('.contacts-search').getElementsByClassName("contacts-search__results")[0];

					const element = document.createElement("div");
					element.className = 'contacts-search__results';
					element.innerHTML = json.html;
					results.parentNode.replaceChild(element, results);
				});
			})
			.catch(err => {
				console.log(err);
			});
	});
})
